var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--join"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot justify-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("JOIN")])]), _c('terms-of-agreements', {
    ref: "terms",
    attrs: {
      "code": _vm.$route.query.code
    }
  }), _c('div', {
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-200px",
    attrs: {
      "outlined": "",
      "block": "",
      "color": "grey-d"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Cancel")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-200px",
    attrs: {
      "block": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("Submit")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }