<template>
    <client-page class="app--join">
        <v-container>
            <div class="tit-wrap tit-wrap--dot justify-center">
                <h2 class="tit">JOIN</h2>
            </div>

            <join-form :code="code" :skin="code"></join-form>
        </v-container>


        <!-- 가입 단계 표시 -->
        <!-- <nav class="join-step">
            <ul class="join-step-lists">
                <li class="list list--on">
                    <span class="badge">STEP 1</span>
                    <span class="text">약관동의</span>
                </li>
                <li class="list list--on">
                    <span class="badge">STEP 2</span>
                    <span class="text">정보입력</span>
                </li>
                <li class="list">
                    <span class="badge">STEP 3</span>
                    <span class="text">가입완료</span>
                </li>
            </ul>
        </nav> -->
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import JoinForm from "@/components/client/join/join-form.vue";
import { USER_TYPES } from '@/assets/variables';

export default {
    components: {
        ClientPage,
        JoinForm,
    },
    computed: {
        code() {
            return this.$route.query?.code || USER_TYPES.PERSON.value;
        },
    },
};
</script>
