<template>
    <component :is="SkinComponent" v-model="user" @submit="submit"></component>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { USER_NOTIFY_METHODS, USER_TYPES } from "@/assets/variables";

const notify = Object.values(USER_NOTIFY_METHODS).map((item) => ({ ...item, method: item.value, enabled: false, contact: null }));

export default {
    props: ["skin", "code"],
    data() {
        return {
            user: {
                _terms: this.$store.state.agreements || [],

                code: this.$props.code || USER_TYPES.PERSON.value,

                username: null,
                name: null,

                phone: null,
                phone1: "010",
                phone2: null,
                phone3: null,

                email: null,
                email1: null,
                email2: null,
                email3: null,

                postcode: null,
                address1: null,
                address2: null,

                _country: null,
                _region: null,
                _zone: null,

                address: {
                    zip: null,
                    state: null,
                    city: null,
                    street: null,
                },

                notify,
                meta: {},
            },
        };
    },

    methods: {
        async submit() {
            var { user } = await api.v1.users.post({
                ...this.user,
                password: CryptoAES.encrypt(this.user.password),
                passwordConfirm: null,
            });

            // if(this.user.meta.businessRegistration) await api.v1.users.postBusinessRegistration(user, this.user.meta.businessRegistration);
            // if(this.user.meta.logo) await api.v1.users.postLogo(user, this.user.meta.logo);
            // if(this.user.photo) await api.v1.users.postPhoto(user, this.user.photo);

            this.$router.push("/join/complete");
        },
    },
    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/form.vue`);
        },
    },
};
</script>
