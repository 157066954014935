<template>
    <client-page class="app--join">
        <v-container>
            <div class="tit-wrap tit-wrap--dot justify-center">
                <h2 class="tit">JOIN</h2>
            </div>

            <terms-of-agreements ref="terms" :code="$route.query.code" />

            <!-- 하단버튼 -->
            <div class="v-btn-group--bottom">
                <v-row justify="center" class="row--small">
                    <v-col cols="6" sm="auto">
                        <v-btn outlined block color="grey-d" class="w-md-200px" @click="$router.go(-1)">Cancel</v-btn>
                    </v-col>
                    <v-col cols="6" sm="auto">
                        <v-btn block color="secondary" class="w-md-200px" @click="submit">Submit</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>


        <!-- 가입 단계 표시 -->
        <!-- <nav class="join-step">
            <ul class="join-step-lists">
                <li class="list list--on">
                    <span class="badge">STEP 1</span>
                    <span class="text">약관동의</span>
                </li>
                <li class="list">
                    <span class="badge">STEP 2</span>
                    <span class="text">정보입력</span>
                </li>
                <li class="list">
                    <span class="badge">STEP 3</span>
                    <span class="text">가입완료</span>
                </li>
            </ul>
        </nav> -->

        <!-- 약관동의 -->
        <!-- <terms-of-agreements ref="terms" :code="$route.query.code" /> -->
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import TermsOfAgreements from "@/components/client/join/terms-of-agreements.vue";

export default {
    components: {
        ClientPage,
        TermsOfAgreements,
    },
    methods: {
        submit() {
            try {
                if (this.$refs.terms.check()) {
                    this.$store.dispatch("agreements", this.$refs.terms.selected);

                    this.$router.push({
                        path: "/join/form",
                        query: this.$route.query,
                    });
                }
            } catch (error) {
                alert(error.message.toString());
            }
        },
    },
};
</script>
