var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--join"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot justify-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("JOIN")])]), _c('div', {
    staticClass: "mw-500px ma-auto"
  }, [_c('v-divider', {
    staticClass: "border-2"
  }), _c('div', {
    staticClass: "text-center py-30 py-md-40 py-lg-50"
  }, [_c('h3', {
    staticClass: "font-size-18 font-size-md-30"
  }, [_vm._v("Successfully Joined")]), _c('p', {
    staticClass: "font-size-16 font-size-md-18 grey--text mt-8 mt-md-14"
  }, [_vm._v("Look around and Feel free to inquiry anything")])]), _c('v-divider')], 1), _c('div', {
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-200px",
    attrs: {
      "block": "",
      "color": "secondary",
      "to": "/"
    }
  }, [_vm._v("Home")])], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }