<template>
    <client-page class="app--join">
        <v-container>
            <div class="tit-wrap tit-wrap--dot justify-center">
                <h2 class="tit">JOIN</h2>
            </div>

            <div class="mw-500px ma-auto">
                <v-divider class="border-2" />
                <div class="text-center py-30 py-md-40 py-lg-50">
                    <h3 class="font-size-18 font-size-md-30">Successfully Joined</h3>
                    <p class="font-size-16 font-size-md-18 grey--text mt-8 mt-md-14">Look around and Feel free to inquiry anything</p>
                </div>
                <v-divider />
            </div>

            <!-- 하단버튼 -->
            <div class="v-btn-group--bottom">
                <v-row justify="center" class="row--small">
                    <v-col cols="6" sm="auto">
                        <v-btn block color="secondary" to="/" class="w-md-200px">Home</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>

        <!-- 가입 단계 표시 -->
        <!-- <nav class="join-step">
            <ul class="join-step-lists">
                <li class="list list--on">
                    <span class="badge">STEP 1</span>
                    <span class="text">약관동의</span>
                </li>
                <li class="list list--on">
                    <span class="badge">STEP 2</span>
                    <span class="text">정보입력</span>
                </li>
                <li class="list list--on">
                    <span class="badge">STEP 3</span>
                    <span class="text">가입완료</span>
                </li>
            </ul>
        </nav> -->
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";

export default {
    components: {
        ClientPage,
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function () {},
    },
};
</script>
